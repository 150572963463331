<template>
    <div>
      <div class="register-property-layout">
      <!-- FROMS -->
      <div class="forms-container container2">
        <ul id="progressbar">
          <!-- <li class="active" :class="[isForm1Valid && formStep == 1 ? 'active' : '']"> -->
          <li class="" :class="[isForm1Valid  ? 'active' : '']">
            {{ isMobile ? 'Info.' : "Landlord's Info" }}
          </li>
          <li :class="[isForm2Valid  ? 'active' : '']">
            {{ isMobile ? "Landlord's Property" : "Landlord's Property" }}
          </li>
          <!-- <li :class="[isForm3Valid  ? 'active' : '']">
            {{ isMobile ? 'Media' : 'Images & media' }}
          </li> -->
          <li :class="[formStep == 3 ? 'active' : '']">
            {{ isMobile ? 'Review' : 'Review' }}
          </li>
        </ul>
        <!-- FORM 1 -->
        <div class="" v-if="formStep == 1">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>General Information</h3>
            <p>
              Complete the form with the landlord’s personal details
            </p>
          </div>

          <div class="container">
            <div class="form">
              <div class="input-group">
                <BaseInput
                  placeholder="Michael"
                  label="First Name"
                  :value="firstName"
                  @input="firstName = $event"
                  :errorMessage="errorMessage.firstName"
                  @blur="validateFirstName"
                />
                <BaseInput
                  label="Last Name"
                  placeholder="Ndubuisi"
                  :value="lastName"
                  @input="lastName = $event"
                  :errorMessage="errorMessage.lastName"
                  @blur="validateLastName"
                />
              </div>
              <div class="input-group">
                <BaseInput
                  type="email"
                  placeholder="example@gmail.com"
                  label="Email Address"
                  :value="email"
                  @input="email = $event"
                  :errorMessage="errorMessage.email"
                  @blur="validateEmail"
                />

                <BaseInput
                  label="Phone number"
                  placeholder="+2348 1437 434"
                  :value="phone"
                  type="number"
                  @input="phone = $event"
                  :errorMessage="errorMessage.phone"
                  @blur="validatePhone"
                />

              </div>

              <BaseInput
                type="textarea"
                label="Landlord address"
                placeholder="Enter full address"
                :value="address"
                @input="address = $event"
                :errorMessage="errorMessage.address"
                @blur="validateLandlordAddress"
              />
            </div>
            <!-- <div class="btn-container  flex-end">
              <BaseButtton @click="validateForm1">Next</BaseButtton>
            </div> -->

            <div class="btn-container"  style="padding: 1rem;">
              <BaseButtton type="text"
                >Cancel</BaseButtton
              >
              <BaseButtton @click="validateForm1">
                <span>Proceed</span>
              </BaseButtton>
            </div>
          </div>
        </div>
       
        <!-- FORM 2 -->
        <div class="" v-if="formStep == 2 && skip === false">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Add property</h3>
            <p class="mb-3" v-if="formStep == 1">
              Please provide us with the details about the property in question.
            </p>
            <p  v-if="formStep == 2" class="mb-3">Add properties owned by this landlord, or skip this step and return to it later.</p>
          </div>

          <div class="form">
            <div class="email-container input-full">
              <BaseInput
                type="text"
                placeholder="Eg: newly built 3 bedroom flat"
                label="Title"
                :value="propertyTitle"
                @input="propertyTitle = $event"
                :errorMessage="errorMessage.title"
                @blur="validateTitleInput"
              />
            </div>

            
            <div class="input-group">
              <InputSelect
                label="Property Type"
                @select-option="propertyType = $event"
                @blur="validatePropertyType"
                :value="propertyType"
                :errorMessage="errorMessage.propertyType"
                :loading="loadingPropertyType"
              >
                <option
                  v-for="propertyType in propertyTypes"
                  :key="propertyType.id"
                  :value="propertyType.type"
                  >{{ propertyType.type }}</option
                >
              </InputSelect>
              <InputSelect
                label="Property subtype"
                @select-option="propertySubType = $event"
                @blur="validatePropertySubType"
                :value="propertySubType"
                :errorMessage="errorMessage.propertySubType"
                :loading="loadingPropertySubType"
              >
                <option
                  v-for="subType in propertySubTypes"
                  :key="subType.id"
                  :value="subType.subtype"
                  >{{ subType.subtype }}</option
                >
              </InputSelect>
              
            </div>

            <div class="input-group">
              <InputSelect
                label="Business Type"
                @select-option="typeofbusiness = $event"
                @blur="validateBusinessType"
                :value="typeofbusiness"
                :errorMessage="errorMessage.typeofbusiness"
              >
                <option selected disabled>Select</option>
                <option  v-for="item in businesstypenumber"
                  :key="item.id"
                  :value="item.id">{{ item.businesstype }}</option>
              </InputSelect>
            </div>

            <!-- <BaseInput
              placeholder="Enter amount"
              label="Amount"
              :value="theRentValueAmount"
              @input="theRentValueAmount = $event"
              :errorMessage="errorMessage.amount2"
              @blur="validatePropertyValue"
            /> -->

            <div class="input-group">
              <InputSelect
                label="State"
                @select-option="location = $event"
                @blur="validateLocation"
                :value="location"
                :errorMessage="errorMessage.location"
                :loading="loadingLocation"
              >
                <option
                  v-for="state in sortedStates"
                  :key="state.id"
                  :value="state.name"
                  >{{ state.name }}</option
                >
              </InputSelect>

              <InputSelect
                label="City"
                @select-option="city = $event"
                @blur="validateCity"
                :value="city"
                :errorMessage="errorMessage.city"
                :loading="loadingCity"
              >
                <option
                  v-for="city in sortedCity"
                  :key="city.id"
                  :value="city.name"
                  >{{ city.name }}</option
                >
              </InputSelect>
              
            </div>
            <!-- <div class="input-group">
              <InputSelect
                label="Status"
                @select-option="propertyStatus = $event"
                @blur="validateStatus"
                :value="propertyStatus"
                :errorMessage="errorMessage.status"
                :loading="loadingStatus"
              >
                <option
                  v-for="status in propertyStatuses"
                  :key="status"
                  :value="status"
                  >{{ status }}</option
                >
              </InputSelect>
            </div> -->
            <div class="email-container input-full">
              <BaseInput
                type="text"
                placeholder="Enter property accurate address"
                label="Property address"
                :value="propertyAddress"
                @input="propertyAddress = $event"
                :errorMessage="errorMessage.propertyAddress"
                @blur="validatePropertyAddress"
              />
            </div>
            <div class="input-group">
              <BaseInput
              type="textarea"
              placeholder="Provide landmarks or directions, a detailed property description, and any additional remarks."
              label="Property description"
              :value="description"
              @input="description = $event"
              :errorMessage="errorMessage.description"
              @blur="validateDescription"
            />
            </div>
            <div class="addNewUnit">
              <div class="unit-text-button">
                <h5>Units</h5>
                <button class="btn" @click="addForm()"><span>+</span> Add unit</button>
              </div>
              <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
            </div>
          
            <div v-for="(form, index) in forms" :key="index">
              <div class="unit-count-holder">
                <h3>Unit {{ index + 1 }}</h3>
                <div>
                  <small>Save</small>
                  <img src="../assets/icons/trash.svg" alt="delete">
                </div>
              </div>
  
              <form @submit.prevent="submitForm(index)">
                <div class="input-group">
                  <div class="row">
                    <div class="col-md-4">
                      <label for="name">Unit name:</label>
                      <input class="form-control dynamic-input" id="name" type="text" v-model="form.name" placeholder="Eg. Main" />
                    </div>
                    <div class="col-md-4">
                      <label for="price">Unit Amount:</label>
                      <input class="form-control dynamic-input" id="price" type="text" v-model="form.amount" placeholder="Enter unit amount" />  
                    </div>
                    <div class="col-md-4">
                      <label for="bedrooms">Number of bedrooms:</label>
                      <select class="form-control dynamic-input" id="bedroom" v-model="form.noOfBedroom">
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <label for="bedrooms">Number of bathrooms:</label>
                      <select class="form-control dynamic-input" id="bathroom" v-model="form.noOfBathroom">
                        <option  value="1">1</option>
                        <option value="2">2</option>
                        <option class="3">3</option>
                        <option class="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <label for="status">Status:</label>
                      <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                        <option value="" disabled>Select status</option>
                        <option
                          v-for="status in propertyStatuses"
                          :key="status"
                          :value="status"
                          >{{ status }}</option
                        >
                      </select>
                       
                    </div>
                    
                  </div>
                </div>
                <!-- <div>
                  <label for="name">Name:</label>
                  <input class="form-control dynamic-input" type="text" v-model="form.name" />
                </div>

                <div>
                  <label for="email">Email:</label>
                  <input class="form-control dynamic-input" type="email" v-model="form.email" />
                </div> -->

                <!-- <button type="submit">Submit Form {{ index + 1 }}</button> -->
              </form>
            </div>
            <div class="btn-container">
              <BaseButtton type="text" @click="formStep--"
                >Previous</BaseButtton
              >
              <BaseButtton @click="validateForm2">Next</BaseButtton>
            </div>
          </div>

         
        </div>
        

        <div class="" v-if="formStep == 2 && skip === true">
            <div class="no-prop-add" @click="skip = false">
              <h2>+</h2>
              <h6>Add Property</h6>
            </div>
            <div class="btn-container"  style="padding: 1rem;" @click="toImage()">
              <BaseButtton type="text"
                >Cancel</BaseButtton
              >
              <div>
                <BaseButtton type="border" class="mr-3">Skip</BaseButtton>
                <!-- <BaseButtton disabled="disabled" @click="validateForm1; nextTick()">
                  <span>Proceed</span>
                </BaseButtton> -->
              </div>
            </div>
          </div>
         <!-- FORM 2 -->

        <!-- FORM 3 -->
        <div class="" v-if="formStep == 3">
          <p class="input-error" v-show="this.errorMessage.form">
            {{ this.errorMessage.form }}***
          </p>
          <div class="container__head">
            <h3>Review</h3>
            <p>
              Check and confirm the information before finalizing.
            </p>
          </div>

          <div class="">
            <div class="review-top-text">
              <h5>General Info.</h5>
              <h6>Modify</h6>
            </div>
            <div class="email-container info-holder">
              <div class="row general-info-content">
                <div class="col-md-6">
                  <h6>Name:</h6>
                  <h5>Afinwaketung Oluremidapo</h5>
                </div>
                <div class="col-md-6">
                  <h6>Email address:</h6>
                  <h5>Afinwaketung.oluremi@gmail.com</h5>
                </div>
                <div class="col-md-6">
                  <h6>Phone number:</h6>
                  <h5>08034567389</h5>
                </div>
                <div class="col-md-6">
                  <h6>Landlord Address:</h6>
                  <h5>43 Oladipo close Lagos, Nigeria</h5>
                </div>
              </div>
            </div>

          </div>

          <div class="">
            <div class="review-top-text">
              <h5>Properties</h5>
              <h6>Modify</h6>
            </div>
            <div class="email-container info-holder">
              <div class="general-info-content mb-0">
                <div class="prop-reviw-holder mb-0">
                  <div>
                    <h5>Three bedroom flat</h5>
                    <h6>25 Lokoja road Abaji Fct, Nigeria</h6>
                    <h6>3 Units</h6>
                  </div>
                  <img src="../assets/icons/menu-icon.svg" alt="menu">
                </div>        
              </div>
            </div>
          </div>
          <div class="btn-container">
            <BaseButtton type="text" @click="formStep--">Cancel</BaseButtton>
            <BaseButtton @click="submitForm" :disabled="processing">
              {{ processing ? "Processing..." : "Submit" }}
            </BaseButtton>
          </div>
        </div>
      </div>
      <!-- <aside class="steps-container">
        <h4>Property registeration</h4>

        <div class="steps">
          <div
            class="step"
            :class="{
              progress: formStep == option.id,
              completed: formStep > option.id,
            }"
            v-for="option in formStepDetails"
            :key="option.id"
          >
            <div class="step-number-container">
              <div class="step-number">
                {{ option.id }}
                <div class="done-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="check">
                      <path
                        id="Vector"
                        d="M13.3337 4L6.00033 11.3333L2.66699 8"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <p class="step-count">Step {{ option.id }}</p>
              <p class="current-step-name">{{ option.text }}</p>
              <div class="status-pill">
                {{
                  formStep == option.id
                    ? "In progress"
                    : formStep > option.id
                    ? "completed"
                    : "pending"
                }}
              </div>
            </div>
          </div>
        </div>
      </aside> -->
    </div>
    </div>
</template>

<script>
// import CF from "../helpers/currencyFormatter";
// import Loader from './Loader';
import properties from "../services/properties";
import BaseButtton from "../components/UI/BaseButtton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import InputSelect from "../components/form/InputSelect.vue";

// import $ from "jquery";
export default {
  name: "AddLandlordProperty",
  components: {
    BaseInput,
    InputSelect,
    BaseButtton,
    // Loader,
  },
  data() {
    return {
        loading: false,
        loadingPropertyType: false,
        loadingPropertySubType: false,
        loadingLocation: false,
        loadingCity: false,
        loadingStatus: false,
        formStep: 1,
        isMobile: false,
        skip: true,
        propertyStatuses: [],
        // title: '',
        propertyTitle: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        propertyAddress: "",
        address: "",
        amount: "",
        amount2: "",
        propertyTypes: [],
        propertySubTypes: [],
        propertySubType: "",
        propertyType: "",
        propertyValue: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        description: "",
        // leaseOrRent: "",
        isFurnished: "no",
        propertyStatus: "",
        businesstypenumber: "",
        propertyAware: "",
        squareFootage: "",
        states: [],
        isEmailValid: false,
        location: "",
        city: "",
        cities: [],
        stateIdd: "",
        propertyAwarenessTypes: [],
        bedroomOptions: [],
        denomination: "naira",
        // businesstype: 1,
        typeofbusiness: "",
        subscription: "",
        subtypeId: "",
        facilities: "",
        evidenceServerFileName: "",
        evidenceFile: "",
        forms: [
          {
            name: '',
            amount: '',
            noOfBedroom: '',
            noOfBathroom: '',
            status: '',
          },
        ],
        errorMessage: {
          title: "",
          propertyTitle: "",
          noOfBedroom: "",
          noOfBathroom: "",
          noOfToilets: "",
          propertyType: "",
          propertySubType: "",
          squareFootage: "",
          description: "",
          // leaseOrRent: "",
          // typeofbusiness: "",
          status: "",
          propertyValue: "",
          form: "",
          firstName: "",
          propertyAddress: "",
          lastName: "",
          email: "",
          phone: "",
          amount: "",
          amount2: "",
          address: "",
          age: "",
          gender: "",
          location: "",
          city: "",
          // evidenceServerFileName: "",
          propertyImageFile: "",
          evidenceFile: "",
        },
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
    this.getPropertyStatuses();
    this.getPropertyTypes();
    this.getStates();
    this.getBusinessTypes();
    // this.getPropertyAwarenessTypes();
  },
  beforeDestroy() {
  },
  methods: {
    nextTick() {
      console.log("disabled")
    },
    addForm() {   
      const lastForm = this.forms[this.forms.length - 1]; // Get the last form
      // Validate if all fields of the last form are filled
      if (!lastForm.name || !lastForm.amount || !lastForm.noOfBedroom || !lastForm.noOfBathroom || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        return;
      }
      // Add a new form if validation passes
      this.forms.push({ name: '', amount: '', noOfBedroom: '',  noOfBathroom: ''});
      console.log("Formssss", this.forms);  // Check if a new form is added
    },
    toImage() {
      console.log("Formssss", this.forms);
    },
    submitForm(index) {
      // Handle form submission for the form at the given index
      console.log('Form Data:', this.forms[index]);
      alert(`Form ${index + 1} Submitted!`);
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },
    validateForm1() {
      if (this.isForm1Valid) {
        this.formStep = 2;
        return;
      }
    },
    validateForm2() {
      if (this.isForm2Valid) {
        this.formStep = 3;
        return;
      }
    },

    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " Please input landlord's first name";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },

    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Please input landlord's last name";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validateLandlordAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " Please input landlord's address";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    
    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.propertySubType = "Please select value";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },
   
    // validateAddress() {
    //   if (this.address.trim() === "") {
    //     this.errorMessage.address = " address required!";
    //     return false;
    //   }
    //   this.errorMessage.address = "";
    //   return true;
    // },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    
    validatePropertyAddress() {
      if (this.propertyAddress.trim() === "") {
        this.errorMessage.propertyAddress = " Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },
    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },
    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },
    checkViewport() {
      this.isMobile = window.innerWidth < 768; // Change 768 to your breakpoint
    },
    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          //fetch subproperty on first load

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },
    

    isForm1Valid() {
      return (
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateEmail() &&
        this.validateLandlordAddress()
        // this.validateTitleInput() &&
        // this.validatePropertyValue() &&
        // this.validatePropertyType() &&
        // this.validatePropertySubType() &&
        // this.validateBusinessType() &&
        // this.validateRented() &&
        // this.validateCity()
        // this.validateStatus()
        

        
      );
    },
    isForm2Valid() {
      return (
        this.validateLocation() && 
        this.validateCity() && 
        this.validateDescription() 
        // this.validateBedrooms() &&
        // this.validateBathrooms() && 
        // this.validateToilets() && 
        // this.validateSquareFootage() &&
      );
    },
    isForm3Valid() {
      return (
        this.validatePropertyDoc() &&
        this.validatePropertyImage()
      )
    },
    isForm4Valid() {
      return this.validateBedrooms();
    },
    isFormValid() {
      return (
        this.validateTitleInput() &&
        // this.validateBedrooms() &&
        // this.validateBathrooms() && 
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateBusinessType() &&
        // this.validateSquareFootage() &&
        // this.validateRented() &&
        // this.validateStatus() &&
        this.validateState() &&
        this.validatePropertyValue() &&
        // this.validatePropertyDoc() &&
        // this.validatePropertyImage() &&
        this.landlordFirstname !== "" &&
        this.landlordLastname !== "" &&
        this.landlordGender !== "" &&
        !isNaN(this.landlordPhone)
      ); 
     
    },
    theRentValueAmount: {
      get() {
        return this.amount;
      },
      set(value) {
        let amountt = value.split(",").join("");
        this.amount2 = (amountt);
        let decimalArray = amountt.split(".");
        if (decimalArray.length === 2) {
          amountt = decimalArray[0];
          amountt = (+amountt).toLocaleString();
          this.amount = amountt + "." + decimalArray[1];
        } else {
          this.amount = (+amountt).toLocaleString();
        }
      },
    },
  },
  watch: {
    propertyType(newType) {
       if (newType) {
        this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
          "id"
        ];
       }

        if (this.subtypeId) {
          this.getPropertySubTypes(this.subtypeId);
        } else {
          this.propertySubTypes = [];
        }
      // if (newType) {
      //    this.subtypeId = this.propertyTypes.find((el) => el.type === newType)[
      //     "id"
      //   ];

      //   if (this.subtypeId) {
      //     this.getPropertySubTypes(this.subtypeId);
      //   } else {
      //     this.propertySubTypes = [];
      //   }
      // }
    },
    location(newState) {
       if (newState) {
        this.stateIdd = this.states.find((el) => el.name === newState)["id"];
       }

        if (this.stateIdd) {
          this.getCities(this.stateIdd);
        } else {
          this.cities = [];
        }
    },
    

  },
};
</script>


<style lang="scss" scoped>


.unit-count-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2B3352;
  }
}
.unit-text-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  button {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #0033ea;
    border-bottom: 1px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.addNewUnit {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    margin-top: 10px;
  }
}

.dynamic-input {
  padding: 1rem 10px !important;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
  height: inherit;
  outline: 0;
  box-shadow: none;
  // height: 48px;
}
.prop-reviw-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
  }
}
.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container2 {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 5px 0;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}



@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}

#progressbar {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  // color: white;
  color: #555C74;
  text-transform: capitalize;
  // font-size: 9px;
  // width: 33.33%;
  width: 24.33%;
  float: left;
  position: relative;

  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;

  // display: flex;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 50px;
  margin: 0 auto 5px auto;
  position: relative;
  z-index: 3;
}


/*progressbar connectors*/

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #A1AEBE;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: 2;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #0033ea;
  color: white;
}
// Progress indicator ends


@media screen and (max-width: 599px) { 
  .container2 {
    // padding: 0;
  }
  .finish-holder {
    margin: 0;
  }
  #progressbar {
    padding-top: 30px;
  }
  #progressbar li {
    font-size: 9px;
   }
}
</style>